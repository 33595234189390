import React, { useState, useEffect } from 'react';
import Dashboard from '../../pages/Dashboard';
import InfoWidget from '../../molecules/Dashboard/InfoWidget';
import { Card, Col, Image, Row, Typography, Button } from 'antd';
import addExpenditure from '../../../assets/svg/add-expenditure.svg';
import addReciept from '../../../assets/svg/add-reciept.svg';
import cashIcon from '../../../assets/svg/cash-on-hand.svg';
import infoBlue from '../../../assets/svg/info-blue.svg';
import dashboardArrow from '../../../assets/svg/dashboard-Arrow.svg';

import FilledReports from '../../molecules/Dashboard/FilledReports';
import { getDashboardData } from './ducks/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popup } from '../../atoms/Popup';
import ImportReceiptsExpenditure from '../../molecules/ImportReceiptsExpenditure';

const { Title, Paragraph } = Typography;
export default (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFederalPAC, setIsFederalPAC] = useState(true);
  const [importCSV, setImportCSV] = useState(false);
  const [importCSVExpenditure, setImportCSVExpenditure] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard.dashboardData);

  useEffect(() => {
    dispatch(getDashboardData());
  }, []);

  useEffect(() => {
    if (dashboardData?.isFederalCommittee) {
      localStorage.setItem('organization', 'federal');
      setIsFederalPAC(false);
    } else {
      setIsFederalPAC(true);
    }
  }, [dashboardData?.isFederalCommittee]);

  // const reportsData = [
  //   {
  //     icon: quarter1,
  //     heading: 'First Quarter Plus',
  //     title: 'Due date:',
  //     date: 'April 30',
  //   },
  //   // {
  //   //     icon: hour48,
  //   //     heading: '48-Hour Notice',
  //   //     title: 'All contributions of $1,000 or more must be reported within 48 hours',
  //   // },
  //   {
  //     icon: yearEnd,
  //     heading: 'Year-End',
  //     subHeading: 'Semiannual',
  //     title: 'Due date:',
  //     date: 'January 28',
  //   },
  // ];

  const importCSVList = {
    closable: false,
    visibility: importCSV,
    class: 'black-modal new_modal',
    content: (
      <ImportReceiptsExpenditure
        title="Add Receipts"
        paragraph="You can add transactions  manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setImportCSV(false)}
        manualLink="/new-receipts"
        afterUploadLink="/import-receipts"
        bulkImportLink="bulkImportReceiptandExpenditureCSV"
      />
    ),
    width: 542,
    onCancel: () => setImportCSV(false),
  };

  const importCSVListExpenditure = {
    closable: false,
    visibility: importCSVExpenditure,
    class: 'black-modal new_modal',
    content: (
      <ImportReceiptsExpenditure
        title="Add Expenditures"
        paragraph="You can add transactions  manually one by one or by uploading them in bulk using a CSV file."
        onClose={() => setImportCSVExpenditure(false)}
        manualLink="/new-expenditure"
        afterUploadLink="/import-expenditures"
        bulkImportLink="bulkImportReceiptandExpenditureCSV"
      />
    ),
    width: 542,
    onCancel: () => setImportCSVExpenditure(false),
  };
  return (
    <Dashboard load={loading} heading="Dashboard">
      <Row gutter={24} justify="space-between" className="dashboard_info">
        {dashboardData?.isUnimportedTransaction && (
          <Col span={24} style={{ marginBottom: 24 }}>
            <Card className="finish_import" onClick={() => history.push('/import-receipts')}>
              <Row gutter={0} align="top">
                <Col flex="0 0 30px" style={{ display: 'flex' }}>
                  <Image src={infoBlue} preview={false} width={30} />
                </Col>
                <Col>
                  <Title level={4} style={{ marginBottom: '8px' }}>
                    Finish Transactions Import
                  </Title>
                  <Paragraph className="mb-0">
                    There's unfinished transactions import process. Click on this card to continue.
                  </Paragraph>
                </Col>
                <Col className="btn_import_dashboard">
                  <Button
                    htmlType="button"
                    type="link"
                    style={{ padding: 14 }}
                    onClick={() => history.push('/import-receipts')}
                  >
                    <Image src={dashboardArrow} preview={false} width={24} />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        {dashboardData?.isUnimportedContact && (
          <Col span={24} style={{ marginBottom: 24 }}>
            <Card className="finish_import" onClick={() => history.push('/import-contacts')}>
              <Row gutter={0} align="top">
                <Col flex="0 0 30px" style={{ display: 'flex' }}>
                  <Image src={infoBlue} preview={false} width={30} />
                </Col>
                <Col>
                  <Title level={4} style={{ marginBottom: '8px' }}>
                    Finish Contacts Import
                  </Title>
                  <Paragraph className="mb-0">
                    There's unfinished contact import process. Click on this card to continue.
                  </Paragraph>
                </Col>
                <Col className="btn_import_dashboard">
                  <Button
                    htmlType="button"
                    type="link"
                    style={{ padding: 14 }}
                    onClick={() => history.push('/import-contacts')}
                  >
                    <Image src={dashboardArrow} preview={false} width={24} />
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        <Col className="info_col">
          <InfoWidget infoData={dashboardData} />
        </Col>
        <Col className="cash_col">
          <Card className="cash_on_hand">
            <div className="cash_icon">
              <Image src={cashIcon} preview={false} width={22} />
            </div>
            <h2>Cash on Hand</h2>
            <div className="cash_text">
              <div className="left_cash">Start of Election Cycle:</div>
              <div className="right_cash">{dashboardData?.cashOnHandData?.startOfElectionCycle}</div>
            </div>
            <div className="cash_text">
              <div className="left_cash">Start of Reporting Period:</div>
              <div className="right_cash">{dashboardData?.cashOnHandData?.startOfReportingPeriod}</div>
            </div>
            <div className="cash_text">
              <div className="left_cash">Current Balance:</div>
              <div className="right_cash">{dashboardData?.cashOnHandData?.currentBalance}</div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={56} justify="space-between" className="dashboard_cards">
        <Col span={11} style={{ paddingRight: 0 }}>
          <Title level={3} className="text-center mb-1">
            Record Transactions
          </Title>
          <Paragraph className="text-center w-100 c-l-blue mb-4">
            The records will be saved and added to the reports you file
          </Paragraph>

          {isFederalPAC && (
            <Card
              className="left-image-card white-card w-100 hover-card"
              style={{ marginBottom: '24px' }}
              onClick={() => history.push('/new-receipts')}
            >
              <Row gutter={20} align="middle">
                <Col flex="0 0 99px" style={{ display: 'flex' }}>
                  <Image src={addReciept} preview={false} width={99} />
                </Col>
                <Col>
                  <Title level={4} style={{ marginBottom: '6px' }}>
                    Add Receipts
                  </Title>
                  <Paragraph className="mb-0">General Contribution, Loan Proceed, Interest, ...</Paragraph>
                </Col>
              </Row>
            </Card>
          )}

          <Card
            className="left-image-card white-card w-100 hover-card"
            style={{ marginBottom: '24px' }}
            onClick={() => history.push('/new-expenditure')}
          >
            <Row gutter={20} align="middle">
              <Col flex="0 0 99px" style={{ display: 'flex' }}>
                <Image src={addExpenditure} preview={false} width={99} />
              </Col>
              <Col>
                <Title level={4} style={{ marginBottom: '6px' }}>
                  Add Expenditures
                </Title>
                <Paragraph className="mb-0">Operating Expense, Coordinated Party Expenditure, ...</Paragraph>
              </Col>
            </Row>
          </Card>

          <Card
            className="left-image-card white-card w-100 hover-card"
            style={{ marginBottom: '24px' }}
            onClick={() => setImportCSVExpenditure(true)}
          >
            <Row gutter={20} align="middle">
              <Col flex="0 0 99px" style={{ display: 'flex' }}>
                <Image src={addExpenditure} preview={false} width={99} />
              </Col>
              <Col>
                <Title level={4} style={{ marginBottom: '6px' }}>
                  Import transactions
                </Title>
                <Paragraph className="mb-0">Receipts and expenditures bulk import using CSV format</Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <FilledReports reportData={dashboardData} />
        </Col>
      </Row>
      <Popup {...importCSVList} />
      <Popup {...importCSVListExpenditure} />
    </Dashboard>
  );
};
