import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Form, Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Dashboard from '../../../../pages/Dashboard';
import { getExemptFormData } from '../ducks/actions';
import { editExpenditure } from '../../../../../services/expenditure';
import { Popup } from '../../../../atoms/Popup';
import ReportConfirmationPopup from '../../../../molecules/ReportConfirmationPopup';
import ReportAmendment from '../../../../molecules/ReportAmendment';
import LimitWarning from '../../../../molecules/LimitWarning';
import LearnMore from '../../../../molecules/ExemptPurchase/LearnMore';
import ExemptForms from '../ExemptForms';
import { addObject } from '../../../../../services/crudApis';

const { Paragraph, Title } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const [formOfPayment, setFormOfPayment] = useState('');
  const { control, handleSubmit, setValue, getValues, errors } = useForm({
    defaultValues: {
      items_sale: [{ name: 'useFieldArray' }],
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split('/')[2];
  const [deletedIndexes, setDeletedIndexes] = useState([]);
  const [learnMorePopup, setLearnMorePopup] = useState(false);
  const formData = useSelector((state) => state.exemptSales.exemptSingleData);

  //console.log('formData', formData);

  useEffect(() => {
    dispatch(getExemptFormData(url));
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    const itemArray = [];
    values?.items_sale?.map((e) => {
      itemArray.push({
        item_id: e?.item_id,
        name: e?.item_name,
        price: e?.price,
        charged_price: e?.charged_price,
        qty: e?.qty,
        anticipated_income: e?.anticipated_income,
      });
    });
    let requestBody = {
      startDate: values?.startDate && moment(values?.startDate).format('YYYY-MM-DD'),
      endDate: values?.endDate && moment(values?.endDate).format('YYYY-MM-DD'),
      submissionDate: values?.submissionDate && moment(values?.submissionDate).format('YYYY-MM-DD'),
      id: url,
      description: values?.description,
      account_id: values?.account_id?.value,
      items: itemArray,
      itemsDeleted: deletedIndexes,
    };

    addObject('updateExempPlan', requestBody)
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
          message.success('Exempt Sale updated.');
          history.push('/exempt-sales');
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const learnMore = {
    closable: false,
    visibility: learnMorePopup,
    class: 'black-modal',
    content: <LearnMore onClose={() => setLearnMorePopup(false)} />,
    width: 450,
    onCancel: () => setLearnMorePopup(false),
  };

  return (
    <>
      <Dashboard load={loading} heading="Exempt Sale" backLink="/exempt-sales">
        <Card className="white-card w-100">
          <Row justify="center" gutter={[20, 20]}>
            <Col span={24}>
              <Title level={2} className="text-capitalize">
                Exempt Sale Plan
              </Title>
            </Col>

            <Col span={24}>
              <Paragraph className="c-blue">
                You must prepare and submit the plan to Board of Elections using{' '}
                <Button
                  type="link"
                  className="c-doger-blue p-0 text-capitalize font-manrope font-400"
                  onClick={() => setLearnMorePopup(true)}
                >
                  CRO-2600 form
                </Button>{' '}
                and get an approval in advance of the
                <br /> event.{' '}
                <a
                  href="https://www.ncsbe.gov/campaign-finance/candidate-committees"
                  className="c-doger-blue"
                  target="_blank"
                >
                  Learn More
                </a>
              </Paragraph>
            </Col>
          </Row>

          <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col span={24}>
                <ExemptForms
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  setLoading={setLoading}
                  formData={formData}
                  deletedIndexes={deletedIndexes}
                  setDeletedIndexes={setDeletedIndexes}
                />
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={() => history.push('/exempt-sales')}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Dashboard>
      <Popup {...learnMore} />
    </>
  );
};
