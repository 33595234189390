import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, Image, Typography, Form, Button, Select, Space, Card, message, Spin, Tooltip } from 'antd';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { InputField, DateField } from '../../../../../atoms/FormElement';
import crossIcon from '../../../../../../assets/svg/cross-icon.svg';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  CONTACTS_POLITICAL_COMMITTEE_TYPE,
  CONTACTS_POLITICAL_COMMITTEE_ORG_LEVEL,
  countryList,
  specificField,
} from '../../../../../../configs/constants';
import { ADD_CONTACT, EDIT_CONTACT } from '../../../../../../configs/endpoints';
import { addObject, editObject } from '../../../../../../services/crudApis';
import { countyList } from '../../../../../../configs/county-list';
import infoIcon from '../../../../../../assets/svg/info-icon.svg';
import moment from 'moment';

const { Paragraph, Title } = Typography;
const { Option } = Select;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const {
    onClose,
    title,
    updateApi,
    data,
    editPopupForm,
    contctValue,
    setTypeIndividual,
    setTypeIndividualPayee,
    isEdit,
  } = props;
  const { control, handleSubmit, errors, setValue, reset } = useForm({
    shouldUnregister: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data)?.length > 0) {
      setLoading(true);

      setTimeout(() => {
        setValue('description', data?.description);
        setValue('date', data?.date && moment(data?.date, 'YYYY-MM-DD'));

        setTimeout(() => {
          setLoading(false);
        }, 1200);
      }, 1200);
    }
  }, [data, editPopupForm]);

  const onFinish = (values) => {
    setLoading(true);
    let requestBody = {
      id: data?.id ? data?.id : '',
      description: values?.description,
      date: values?.date && moment(values?.date).format('YYYY-MM-DD'),
    };

    addObject('/updateExempPlan', requestBody)
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
          message.success('Contact saved');
        }, 800);
        setTimeout(() => {
          reset();
          updateApi(response?.data);
        }, 1200);
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const closingPopUp = () => {
    setTypeIndividual && setTypeIndividual(false);
    setTypeIndividualPayee && setTypeIndividualPayee(false);
    reset();
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form className="w-100 mt-2" layout="vertical" name="normal_login" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[48, 20]} justify="space-between" align="middle">
          <Col>
            <Title level={4} className="mb-0">
              {!isEdit ? 'Add Exempt Sales Plan' : 'Edit Exempt Sales Plan'}
            </Title>
          </Col>
          <Col>
            <Button size="large" type="link" className="p-0" onClick={closingPopUp}>
              <Image src={crossIcon} preview={false} size={48} />
            </Button>
          </Col>
          {!isEdit && (
            <Col span={24}>
              <Card className="info-card mb-2">
                <Space size={20}>
                  <Image src={infoIcon} width={30} preview={false} />
                  <div className="info_text">
                    <Paragraph className="smallFont14 w-100 mb-0">
                      You must prepare and submit the plan to Board of Elections using{' '}
                      <a
                        className="c-doger-blue"
                        href="https://www.figma.com/exit?url=https%3A%2F%2Fs3.amazonaws.com%2Fdl.ncsbe.gov%2FCampaign_Finance%2FForms%2Fcro2600%2Fcro2600.pdf"
                        target="_blank"
                      >
                        CRO-2600 form
                      </a>{' '}
                      and get an approval in advance of the event.
                    </Paragraph>
                  </div>
                </Space>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <InputField
              isRequired={true}
              fieldname="description"
              label="Plan Description"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
              }}
              rules={{
                required: 'Plan Description is required.',
              }}
              validate={errors.description && 'error'}
              validMessage={errors.description && errors.description.message}
              tooltip={true}
              tooltipTitle="Indicate a brief description of the exempt sale that will occur. An example could be “Fall t-shirt sale” or “Apple pie at the fair event”.."
            />
          </Col>

          <Col span={24}>
            <DateField
              fieldname="date"
              label="Plan Submission Date"
              control={control}
              class="mb-0"
              iProps={{ size: 'large' }}
              initValue=""
              isRequired={true}
              rules={{
                required: 'Plan Submission Date is required.',
              }}
              validate={errors.date && 'error'}
              validMessage={errors.date && errors.date.message}
            />
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" className="mt-2" gutter={[20, 20]}>
              <Col>
                <Button
                  type="primary"
                  onClick={closingPopUp}
                  htmlType="button"
                  className="font-gilroy white-btn"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button type="primary" htmlType="submit" className="font-gilroy" size="large">
                  {!isEdit ? 'Save Plan' : 'Save Changes'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
